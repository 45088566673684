/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */
import 'hammerjs';
import { environment } from '@app/environments/environment';


/***************************************************************************************************
 * APPLICATION LOGO
 */
const version = `MSL Mini Games v. ${environment.version}`;
console.log(`${'-'.repeat(80)}\n${' '.repeat((80 - version.length) / 2)}${version}\n${'-'.repeat(80)}\n~~~ Start App`);


/***************************************************************************************************
 * CUSTOM POLYFILLS
 */
if (!Object.entries) {
	console.log('~~~ Polyfill add | Object.entry');
	Object.entries = (obj) => {
		const ownProps = Object.keys(obj);
		let i = ownProps.length;
		const resArray = new Array(i);
		while (i--) {
			resArray[i] = [ownProps[i], obj[ownProps[i]]];
		}

		return resArray;
	};
}

if (!Object.values) {
	console.log('~~~ Polyfill add | Object.values');
	Object.values = obj => Object.keys(obj).map(k => obj[k]);
}

if (!Array.prototype.includes) {
	console.log('~~~ Polyfill add | Array.prototype.includes');
	Object.defineProperty(Array.prototype, 'includes', {
		value: function (r, e) {
			if (null == this) throw new TypeError('"this" is null or not defined');
			let t = Object(this), n = t.length >>> 0;
			if (0 === n) return !1;
			let i, o, a = 0 | e, u = Math.max(0 <= a ? a : n - Math.abs(a), 0);
			for (; u < n;) {
				if ((i = t[u]) === (o = r) || 'number' == typeof i && 'number' == typeof o && isNaN(i) && isNaN(o)) return !0;
				u++;
			}

			return !1;
		}
	});
}

if (typeof window.CustomEvent !== "function" ) {
	console.log('~~~ Polyfill add | CustomEvent');

	function CustomEvent(event, params) {
		params = params || {bubbles: false, cancelable: false, detail: undefined};
		const evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;

	// @ts-ignore
	window.CustomEvent = CustomEvent;
}
